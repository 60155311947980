<template>
  <div class="office" style="width: 100%">
    <!-- 顶部壁纸 -->
    <div class="page-head">
      <el-image
        class="banner-img"
        :src="require('../images/smart-elderly/office-banner.png')"
        alt=""
        fit="cover"
      />
      <!-- bannner 图上的文字 -->
      <div class="office-banner-txt-box">
        <p class="office-banner-txt-title">掌上办公</p>
        <p class="office-banner-txt-small-title">智慧养老移动平台全覆盖</p>
        <p class="office-banner-txt-desc desc-first block-md-down">
          解决工作者不会用、用不起来的问题，
        </p>
        <p class="office-banner-txt-desc block-md-down">
          我们致力于每一位工作者都能够轻松使用的移动养老服务平台
        </p>
        <button class="Apply-btn clearFix" @click="onClickToTry">申请试用</button>
      </div>
      <div>
        <el-image
          class="mobile-office hidden-sm-and-down"
          :src="require('../images/smart-elderly/mobie-office.png')"
          alt=""
          fit="cover"
        />
      </div>
    </div>
    <!-- 我们的产品 -->
    <div class="col-center product-matter">
      <products></products>
    </div>
    <!-- 养老多角色适配 -->
    <div class="col-center more-role-matter">
      <div class="module-title">养老多角色适配</div>
      <moreRole></moreRole>
    </div>
    <!-- 监控大屏配合 -->
    <div class="col-center monitor-matter">
      <div class="module-title">监控大屏配合</div>
      <monitorScreen class="monitor-screen"></monitorScreen>
    </div>
    <!-- 多角色配合展示 -->
    <div class="col-center role-coopertate">
      <div class="module-title role-coopertate-title">多角色配合展示</div>
      <roleCooperate></roleCooperate>
    </div>
    <!-- 预约我们的服务 -->
    <div class="col-center appoint-serice">
      <div class="module-title">预约我们的服务</div>
      <appointSerice></appointSerice>
    </div>
  </div>
</template>

<script>
import products from "@/components/office/Products.vue";
import moreRole from "@/components/office/MoreRole.vue";
import monitorScreen from "@/components/office/MonitorScreen.vue";
import roleCooperate from "@/components/office/RoleCooperate.vue";
import appointSerice from "@/components/office/AppointSerice.vue";



export default {
  components: {
    products,
    moreRole,
    monitorScreen,
    appointSerice,
    roleCooperate,
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
    onClickToTry(){
      this.$router.push("/apply")
    },

  },
};
</script>
import 'element-ui/lib/theme-chalk/display.css';
<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}

.office {
  background: #fff;
  // 标题
  .module-title {
    font-size: 35px;
    font-weight: 500;
    color: #000530;
  }
  //  banner图 及 文字
  .page-head {
    width: 100%;
    position: relative;
    .banner-img {
      width: 100%;
      height: 530px;
    }
    .office-banner-txt-box {
      animation: office-banner-txt-box 0.5s ease-out both;
    }
    @keyframes office-banner-txt-box {
      0% {
        transform: translateX(-150%)
      }
      100% {
        transform: translateX(-120%)
      }
    }
      .mobile-office{
        position absolute;
        top: 32px;
        left: 50%;
        transform: translate(100%, 0%);
        width 242px;
        height 484px;
      }
    .office-banner-txt-box {
      //background pink;
      max-width: 1440px;
      height: auto;
      position: absolute;
      top: 64px;
      left: 50%;
      transform: translate(-120%, 0%);
      //padding-left: 157px;
      box-sizing: border-box;
      text-align: left;
      p {
        margin: 0;
        padding: 0;
      }

      .office-banner-txt-title {
        font-size: 38px;
        font-weight: 600;
        color: #000530;
      }

      .office-banner-txt-small-title {
        font-size: 22px;
        color: #000530;
        margin-top: 16px;
      }

      .office-banner-txt-desc {
        font-size: 16px;
        color: #000530;
      }

      .desc-first {
        margin-top: 16px;
      }

      .Apply-btn {
        width: 156px;
        height: 45px;
        background: #FC822C;
        border-radius: 8px;
        font-size: 20px;
        color: #FFFFFF;
        border: none;
        outline: none;
        margin-top: 30px;
        transform: scale(1);
        transition: transform 0.5s ease-in-out;
        cursor: pointer;
      }


      .Apply-btn:hover {
        transform: scale(1.2);
        transition: transform opacity 0.5s ease-in-out;

      }
    }

    .page-head-content {
      height: 530px;
    }
  }

  //  我们的产品
   .product-matter {
    padding-top: 60px;
  }
  // 养老多角色
  .more-role-matter {
    background: #f4f6f9;
    padding-top: 60px;
    margin-top: 120px;
  }
  //监控设备
  .monitor-matter {
    padding: 60px 0 150px 0;
    background: #fff;
    margin: 0 auto;
    .monitor-screen {
      margin-top: 65px;
    }
  }
  //多角色配合
  .role-coopertate {
    max-width: 1440px;
    background: linear-gradient(360deg, #417DFF 0%, #41ACFF 100%);
    padding-top: 60px;
    box-sizing: border-box;
    .role-coopertate-title {
      color: #fff !important;
    }
  }
  //预约
  .appoint-serice {
    padding-top: 60px;
  }
}



@media screen and (max-width: 1200px) {
  .mobile-office{
    transform: translate(50%, 0%) !important;
  }
  .office-banner-txt-box {
    //background pink !important ;
    transform: translate(-80%, 0%) !important;

  }
}

@media screen and (max-width: 768px) {
  .office-banner-txt-box {
    //background pink !important ;
    transform: translate(-50%, 0%) !important;

  }
  .office-banner-txt-box{
    margin 0;
    padding 0;
  }
  .block-md-down{
    display none;
  }
  .Apply-btn{
    margin 0 auto;
  }
  .office-banner-txt-small-title{
    text-align center !important;
    font-size 16px !important;
    margin-top: 10px !important;

  }
  .office-banner-txt-title{
    text-align center !important;
    font-size 30px !important;
  }
  .Apply-btn{
    text-align center !important;
    width: 120px !important;
    height: 34px !important;
    font-size: 16px !important;
    margin-top: 20px !important;
  }
  .office-banner-txt-box{
    text-align center !important;
  }
  .page-head{
    .banner-img{
      height 300px !important;
    }
    .mobile-office{
      width 121px !important;
      height 242px !important;
      transform: translate(50%, 0%) !important;
      }
  }
}
</style>
