<template>
  <div class="scene-contain">
    <el-row :gutter="30">
      <el-col
        :xs="12"
        :sm="12"
        :md="8"
        :lg="6"
        :xl="6"
        v-for="(item, index) in imgData"
        :key="index"
      >
        <div class="scene-item">
          <img class="scene-img" :src="item.imgUrl" alt="" />
          <div class="scene-title">{{ item.title }}</div>
          <div class="scene-content">
            {{ item.content }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgData: [
        {
          imgUrl: require("../../images/smart-elderly/icon-people.png"),
          title: "养老助餐系统",
          content:
            "基于大数据、物联网等新技术打造新型养老助餐系统，通过线上线下一体化结合取餐，为辖区老年人提供多样化的供餐服务。",
        },
        {
          imgUrl: require("../../images/smart-elderly/menu.png"),
          title: "老人儿童随访",
          content:
            "实时监护老人的身体、行动能力及照护情况，处理老人生活的问题，同时对留守儿童家庭访视，询问观察和体格检查，做到老人儿童健康指导。",
        },
        {
          imgUrl: require("../../images/smart-elderly/transfrom.png"),
          title: "适老化改造",
          content:
            "解决老人在日常生活中遇到的问题，增强老人生活的安全性和便利性，包括建筑硬件改造、家具家装改造、辅具配备，智能化用具配备。 ",
        },
        {
          imgUrl: require("../../images/smart-elderly/home.png"),
          title: "居家养老",
          content:
            "以信息化平台为支撑，以智能终端和热线为纽带，整合服务资源，打造居家养老、社区养老、机构养老服务，为老年人提供综合性的养老服务。。",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.scene-contain{
  padding 0 10px;
  .scene-item {
    //width: 287px;
    height: 408px;
    box-shadow: 0px 0px 8px 1px #EDF3FC;
    padding: 40px 30px 0 30px;
    box-sizing: border-box;
    background: #fff;
    margin-top 30px;
    .scene-img {
      width: 55px;
      height: 60px;
    }

    .scene-title {
      font-size: 24px;
      font-weight: 500;
      color: #003A6C;
      margin-top: 20px;
    }

    .scene-content {
      font-size: 16px;
      color: #999999;
      line-height: 22px;
      margin-top: 20px;
      text-align: left;
    }
  }
}
@media screen and (max-width: 768px) {
  .scene-item{
    padding: 40px 10px 0 10px !important;
  }
  .scene-title {
    font-size: 18px !important;
    font-weight: 500;
    color: #003A6C;
    margin-top: 20px;
  }

  .scene-content {
    font-size: 16px;
    color: #999999;
    line-height: 22px;
    margin-top: 20px;
    text-align: left;
  }

}
</style>
