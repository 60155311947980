<template>
  <div class="scale-box">
    <el-row class="col-center scale-item-box">
      <el-col class="scale-item" :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="scale-item-title">服务人群覆盖</div>
        <div class="scale-item-number">2000万</div>
      </el-col>

      <el-col class="scale-item" :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="scale-item-title">智慧养老指挥中心</div>
        <div class="scale-item-number">100<span>个</span></div>
      </el-col>
      <el-col class="scale-item" :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="scale-item-title">每天服务</div>
        <div class="scale-item-number">5万<span>次</span></div>
      </el-col>
      <el-col class="scale-item" :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="scale-item-title">每天拯救老人</div>
        <div class="scale-item-number">30<span>个</span></div>
      </el-col>
      <el-col class="scale-item" :xs="12" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="scale-item-title">覆盖区县</div>
        <div class="scale-item-number">218<span>个</span></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
//.scale-matter {
//  padding-top: 65px;
//  background: #fff;
//  box-size: border-box;
//  max-width: 1252px;
//  margin: 0 auto;
.scale-box{
    max-width: 1252px;
  margin 0 auto;
}
  .scale-item-box {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 63px;
  }

  .scale-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .scale-item-title {
      font-size: 24px;
      font-weight: 500;
      color: #fc822c;
    }

    .scale-item-number {
      width: 191px;
      height: 107px;
      line-height: 107px;
      text-align: center;
      font-size: 50px;
      font-weight: 500;
      color: #fc822c;
      border-radius: 8px;
      border: 2px solid #fc822c;
      margin-top: 30px;

      span {
        font-size: 20px;
      }
    }
  //}
}
@media screen and (max-width: 1200px) {
  .scale-box{
    .scale-item{
      margin-top 40px;
    }
    .scale-item-number{
      width: 152px !important;
      height: 85px !important;
      font-size: 40px !important;
      margin-top: 10px !important;
    }
  }

}
</style>
