<template>
  <div id="app">
    <div class="top-header">
      <navbar ref="navBar" :childEvent="handleChildEvent"></navbar>
    </div>
    <div v-if="showFillBox" class="top-fill-box"></div>

    <router-view :key="$route.fullPath" />
    <div class="footer-view">
      <footView ></footView>
    </div>
  </div>
</template>
<script>
import { setStore, getStore } from "./utils/storage";
import constant from "./constant";
import navbar from "@/components/navbar.vue";
import footView from "@/components/FootView.vue";
export default {
  components: {
    navbar,
    footView,
  },
  data() {
    return {
      token: "",
      routeName: "",
      showFillBox: true,
    };
  },
  created() {
    this.token = getStore(constant.K_TOKEN);
  },

  updated() {
    this.routeName = this.$route.name;

    // this.activeIndex2 = 99
    // if (this.routeName == "Index") {
    //   this.activeIndex = "0";
    // } else if (
    //   this.routeName == "HomeSystem" ||
    //   this.routeName == "CommonSystem"
    // ) {
    //   this.activeIndex = "1";
    //   if (this.routeName == "HomeSystem") {
    //     this.activeIndex2 = 0;
    //   } else if (this.routeName == "CommonSystem") {
    //     this.activeIndex2 = 1;
    //   }
    // } else if (this.routeName == "Office") {
    //   this.activeIndex = "3";
    // } else if (this.routeName == "Device") {
    //   this.activeIndex = "2";
    // } else if (this.routeName == "About") {
    //   this.activeIndex = "4";
    // }
    this.handleChildEvent(this.routeName)
  },

  methods: {
    //
    changeBarStatus() {
      // 这是关键
      // this.$refs.navBar.handleNavBarStatus();
    },
    handleChildEvent(routeName) {
      if (routeName == "About" || routeName == "Apply") {
        this.showFillBox = false;
      } else {
        this.showFillBox = true;
      }
      this.$refs.navBar.handleNavBarStatus();
    },
    onClickToPage(item) {
      if (item == "/about") {
        this.activeIndex = 4;
      } else if (item == "/apply") {
        this.activeIndex = 99;
      }
      this.$router.push(item).catch((err) => {});
    },
    toGoNextPage(item, index) {
      this.activeIndex = index;
      this.activeIndex2 = 99;

      if (index == 1) {
        this.activeIndex2 = 0;
      }
      var route = item.routeName ? "/" + item.routeName : "";
      this.$router.push(route).catch((err) => {});
      if (index == 0) {
        this.$router.replace("/");
      }
    },
    handleSelect(key, keyPath) {
      // console.log(key)
      this.$router.push(
        key == 2
          ? "/system/home"
          : key == 3
          ? "/office"
          : key == 4
          ? "/device"
          : key == 5
          ? "/case"
          : "/"
      );
    },
    logoutFun() {
      this.token = "";
      setStore(constant.K_TOKEN, "");
      this.$router.go(0);
    },
    onJumpMineCenter() {
      this.$router.push("/mine/base_info");
    },
    onJumpMsgCenter() {
      this.$router.push("/msg");
    },
    onJumpSetting() {
      this.$router.push("/mine/modfiy_pwd");
    },
    onClickRegister() {
      this.$router.push("/register");
    },
    onClickLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
@import './common/stylus/mixin.styl';

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF !important;
  padding-bottom 0 !important;
}
li {
  list-style-type: none;
}
.centerContent {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}
.top-fill-box {
  width: 100%;
  height: 64px;
  background transparent;
}
.top-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 64px;
}
.footer-view {
  width: 100%;
  min-height: 406px;
  background: #383838;
  padding-bottom 0;
}
.btn-top-opt {
  height: 34px !important;
  line-height: 0 !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #666666 !important;
}
</style>
