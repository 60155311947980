import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './styles/index.css'
import './common/stylus/index.styl'






Vue.config.productionTip = false

import Vuex from 'vuex'
Vue.use(Vuex)


// import  api  from 'http/index'
// // 此时可以直接在 Vue 原型上调用 $api 了
// Vue.use(api)

// 全局使用
// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);


// vant按需引入

// import {Image as VanImage, Swipe, SwipeItem, Toast, Notify ,Lazyload, Search,
//   Tab, Tabs, Popover, Button, Empty, Dialog, Overlay,Checkbox, CheckboxGroup, Field} from 'vant'
// import 'vant/lib/index.css'
//
// Vue.use(Swipe).use(SwipeItem).use(Toast).use(Notify).use(Lazyload).use(VanImage)
//   .use(Search).use(Tab).use(Tabs).use(Popover).use(Button).use(Empty).use(Dialog)
//   .use(Overlay).use(Checkbox).use(CheckboxGroup).use(Field)


import ElementUI from 'element-ui';
import './styles/theme/index.css';
Vue.use(ElementUI);

import { setStore, getStore, removeStore } from './utils/storage'
import constant from './constant'
Vue.prototype.setStore = setStore
Vue.prototype.getStore = getStore
Vue.prototype.removeStore = removeStore

import filter from './filter'

/**
 * to：router即将进入的路由对象。
 from：当前导航正要离开的路由。
 next：一个function，一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
 next() : 进行管道中的下一个钩子。如果全部钩子执行完了，则导航的状态就是 confirmed （确认的）。
 next(false) : 中断当前的导航。如果浏览器的 URL 改变了（可能是用户手动或者浏览器后退按钮），那么 URL 地址会重置到 from 路由对应的地址。
 next('/') 或者 next({ path: '/' }): 跳转到一个不同的地址。当前的导航被中断，然后进行一个新的导航。 你可以向 next 传递任意位置对象，且允许设置诸如 replace: true、name: ‘home' 之类的选项以及任何用在 router-link 的 to prop 或 router.push 中的选项，注意，next可以通过query传递参数。
 next(error) : (2.4.0+) 如果传入 next 的参数是一个 Error 实例，则导航会被终止且该错误会被传递给 router.onError() 注册过的回调。
 */

router.beforeEach((to, from, next) => {

  if (to.meta && to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  next()
});
router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
});


new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
