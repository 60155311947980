<template>
  <div>
    <el-row class="about-us-row">
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="padding: 0 10px">
        <el-image
          class="intro-img"
          :src="require('../../images/smart-elderly/intro-bg.png')"
          alt=""
          fit="cover"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="intro">
          <p>安徽木子信息技术有限公司成立于2012年。</p>
          <p>
            公司自主开发28大智慧养老服务系统，打造“社区居家养老、智慧型养老院、政府购买服务、智慧助餐、家庭养老床位、适老化改造、呼叫调度、智慧评估、养老服务时间银行、农村互助积分养老、物业+养老、空巢独居困难老年人探访关爱”等12大智慧养老解决方案。
          </p>
          <p>
            自主研发“智能一键呼——呼小雷1、2代、智慧养老服务终端——呼小爱、动态监管二维码、床头一体呼叫监测设备带、智能刷卡呼叫器、指静脉识别监管设备”等智能养老硬件产品。
          </p>
          <p>
            业务现已覆盖江苏、安徽、天津、浙江、重庆、四川、广东、河北、山东、山西、青海、湖南、湖北、甘肃、河南、陕西、内蒙等18个省份67个地级市。
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.about-us-row {
  padding: 0;
  max-width: 1240px;
  margin 0 auto;
  margin-top: 116px;
}

.intro-img {
  max-width: 419px;
  height: auto;
  // margin-left: 36px;
}

.intro {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #737C8B;
  line-height: 25px;
  text-align: left;
  box-sizing: border-box;
  padding 0 20px;
  padding-left 60px;
  box-sizing border-box;
  p {
    text-indent: 40px;
  }

  p:nth-child(1) {
    text-indent: 0;
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .intro {
    padding 0 20px;
  }
}
</style>
