<template>
  <div class="common-system">
    <div class="home-system-banner">
      <el-image
        class="home-system-banner-img"
        :src="require('../images/smart-elderly/provide-old-banner.png')"
        alt=""
        fit="cover"
      />
      <div class="home-system-banner-txt-box">
        <p class="home-system-banner-txt-title">****养老解决方案</p>
        <p class="home-system-banner-txt-desc">
          解决工作者不会用、用不起来的问题，
        </p>
        <p class="home-system-banner-txt-desc">
          我们致力于每一位工作者都能够轻松使用的移动养老服务平台
        </p>
      </div>
    </div>
    <div class="col-center home-system-title">****养老解决方案</div>
    <div class="col-center home-system-content">
      <div class="home-system-content-title">
        <div class="blue-block" />
        行业痛点
      </div>
      <div class="home-system-content-content">
        <p>
          2014-2050年间，中国老年人口的消费潜力将从4万亿左右增长到106万亿左右，占GDP的比例将从8%左右增长到33%左右，老龄产业将逐渐进入到快速发展阶段，迎来老龄产业发展黄金期。同时，政府多次发文规划养老产业，并出台各种优惠政策及补贴政策，要求加快智能化养老发展等，“十三五”规划提出“积极应对人口老龄化”。
        </p>
        <p>
          受中华民族传统的家庭伦理观念影响，我国大多数老年人不愿离开自己的家庭和社区，到一个新的环境去养老。****养老服务采取让老年人在自己家里和社区接受生活照料的服务形式，适应了老年人的生活习惯，满足了老年人的心理需求，有助于他们安度晚年。
        </p>
      </div>
    </div>
    <!-- 我们的解决方案 -->
    <div class="col-center home-system-content solution-matter">
      <div class="home-system-content-title">
        <div class="blue-block" />
        我们的解决方案
      </div>
      <div class="home-system-content-content">
        <el-row class="home-system-row" :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="solution-left-content">
              <p>
                安徽木子信息公司开发的智慧养老服务平台是在深刻理解国家政策和社区服务特点的基础上，针对民政部门及相关社区服务机构创建养老服务体系、创建中国特色养老服务模式的需求，而开发的一套信息化应用系统。
              </p>
              <p>
                智慧养老服务模式“系统+服务+老人+终端”，以社区为依托、以智慧养老服务平台为支撑，以智能终端和热线为纽带，整合社区养老服务设施、专业服务队伍和社会资源，重点打造以“呼叫救助、照料、健康服务、档案管理”为中心的智能养老服务网络，为老年人提供综合性的养老服务。
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="solution-right-content">
              <el-image
                class="solution-right-content-img"
                :src="require('../images/smart-elderly/solution-2.png')"
                alt=""
                fit="cover"
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- ****养老服务流程 -->
    <div class="col-center home-system-content">
      <div class="home-system-content-title">
        <div class="blue-block" />
        ****服务流程
      </div>
      <el-row class="prograss-content">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-image
            class="service-progress"
            :src="require('../images/smart-elderly/service-progress.png')"
            alt=""
            fit="cover"
          />
        </el-col>
      </el-row>
    </div>
    <!-- <div>****养老平台实时服务监管</div> -->
    <!--    <div class="col-center home-system-content">-->
    <!--      <div class="home-system-content-title">-->
    <!--        <div class="blue-block" />-->
    <!--        ****养老平台实时服务监管-->
    <!--      </div>-->
    <!--      <div class="home-system-content-content">-->
    <!--        <el-image-->
    <!--          class="service-supervise"-->
    <!--          :src="require('../images/smart-elderly/service-supervise.png')"-->
    <!--          alt=""-->
    <!--          fit="cover"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- <div>智能化解决方案</div> -->
    <div class="col-center home-system-content">
      <div class="home-system-content-title">
        <div class="blue-block" />
        ****平台实时服务监管
      </div>
      <div class="home-system-content-content">
        <el-image
          class="smart-solution"
          :src="require('../images/smart-elderly/large-screen.png')"
          alt=""
          fit="cover"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: "../images/smart-elderly/provide-old-banner.png",
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}
.common-system {
  width: 100%;
  background: #fff;
  padding-bottom: 150px;
  .home-system-banner {
    width: 100%;
    .home-system-banner-img {
      width: 100%;
      height: 531px;
    }
  }
  //banner文字
  .home-system-banner-txt-box {
    animation: home-system-banner-txt-box 0.5s ease-out both;
  }
  @keyframes home-system-banner-txt-box {
    0% {
      transform: translateX(-150%)
    }
    100% {
      transform: translateX(-120%)
    }
  }
  .home-system-banner-txt-box {
    max-width: 1440px;
    height: 531px;
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translate(-120%, 0%);
    //padding-left: 157px;
    box-sizing: border-box;

    .home-system-banner-txt-title {
      font-size: 38px;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 139px;
    }

    .home-system-banner-txt-desc {
      padding: 0;
      margin: 0;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
    }
  }
  //模块标题
  .home-system-title {
    font-size: 35px;
    font-weight: 500;
    color: #000530;
    border-bottom: 1px dashed #DDDDDD;
    padding: 35px 0;
  }
  //小标题
  .home-system-content-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #417DFF;

    .blue-block {
      width: 24px;
      height: 24px;
      background: #417DFF;
      margin-right: 24px;
    }
  }
  //行业痛点
  .home-system-content-content {
    font-size: 18px;
    color: #333333;
    line-height: 35px;
    width 100%;
    p {
      text-align: left;
      text-indent: 30px;
    }
  }
  //解决方案
  .home-system-row {
    margin: 0;
    padding: 0;
  }
  .solution-right-content {
    position: relative;
    .solution-right-content-img {
      max-width: 653px;
      height: auto;
    }
  }
  .solution-matter {
    margin-top: 170px !important;
  }
  .solution-left-content {
    text-align: left;
    font-size: 18px;
    color: #333333;
    line-height: 35px;
    margin-top: 80px;
  }

  //小标题+图片内容
  .home-system-content {
    width 100%;
    margin-top: 30px;
    padding 0 20px;
    box-sizing border-box;
    .smart-solution {
      width: 100%;
      height: auto;
      margin-top: 110px;
    }
    .service-supervise {
      width: 100%;
      height: auto;
      margin-top: 110px;
    }
    .service-progress {
      width: 100%;
      height: auto;
      margin-top: 77px;
    }

  }
}

@media screen and (max-width: 768px) {
  .home-system-content{
    padding 0 10px;
  }
  .home-system-banner-txt-box{
    display none;
  }
  .home-system-banner-img{
    height 300px !important;
  }
}
</style>
