<template>
  <div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  }
};
</script>

<style lang='stylus' rel='stylesheet/stylus'>

</style>