import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import Article from '../views/Article'


import Office from "@/views/Office.vue";
import CommonSystem from "@/views/CommonSystem.vue";
import HomeSystem from "@/views/HomeSystem.vue";

import Com from "@/views/CommonSystem.vue";
import CommunitySystem from "@/views/CommonSystem.vue";
import InstitutionSystem from "@/views/CommonSystem.vue";
import FamilyNursingBed from "@/views/CommonSystem.vue";
import MealSystem from "@/views/CommonSystem.vue";
import VisitSystem from "@/views/CommonSystem.vue";
import ElderlyTransformSystem from "@/views/CommonSystem.vue";
import DisabledElderHelpSystem from "@/views/CommonSystem.vue";

import Device from "@/views/Device.vue";
import Case from "@/views/Case.vue";
import About from "@/views/About.vue";
import Apply from "@/views/Apply.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      // 页面标题title
      title: '木子科技_数字养老系统',
      content: {
        keywords: '居家养老,社区养老,机构养老,适老化改造,关爱老人探视走访,失能老人帮扶,老年人助餐,家政公司',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },
  {
    path: '/office',
    name: 'Office',
    component: Office,
    meta: {
      // 页面标题title
      title: '掌上办_智慧养老移动办公平台_数字养老系统_木子科技',
      content: {
        keywords: '掌上办,移动办公,居家养老,社区养老,机构养老,适老化改造,关爱老人探视走访,失能老人帮扶,老年人助餐,家政公司',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },{
    path: '/system/home',
    name: 'HomeSystem',
    component: HomeSystem,
    meta: {
      // 页面标题title
      title: '居家养老_居家养老解决方案_数字养老系统_木子科技',
      content: {
        keywords: '居家养老,居家养老系统,居家养老解决方案',
        description: '木子科技为您提供智能居家养老服务平台，24小时无间断监测，子女可随时查看老人情况。'
      }
    }
  },
  {
    path: '/system/common',
    name: 'CommonSystem',
    component: CommonSystem,
    meta: {
      // 页面标题title
      title: '智慧养老_数字养老系统_木子科技',
      content: {
        keywords: '老人、养老、医疗、健康',
        description: '木子科技成立于1998年，是国内最大的养老服务型企业，以自主运营的居家呼叫中心、自主研发的信息管理系统、专业化的家庭服务团队为基础，整合优质社会资源，为广大老年人提供全方位的居家生活安心护航服务。目前，木子科技已在北京、上海、江苏、山东、广西、海南、江西、天津、内蒙古、陕西、四川、重庆、云南、黑龙江等城市开展业务，拥有超过几百万的国内老年用户。2013年，木子科技加入三胞集团，成为三胞集团健康医疗产业链上的重要一环，在资金、技术、人员等各个方面都完成了质的飞跃，为木子科技后续的智慧居家养老服务产业化发展战略铺平了道路'
      }
    }
  },
  {
    path: '/system/community',
    name: 'CommunitySystem',
    component: CommunitySystem,
    meta: {
      // 页面标题title
      title: '社区养老_社区养老解决方案_数字养老系统_木子科技',
      content: {
        keywords: '社区养老,社区养老解决方案,社区养老系统',
        description: '木子科技开发了智慧社区养老信息管理系统，给您提供社区养老解决方案。'
      }
    }
  },
  {
    path: '/system/institution',
    name: 'InstitutionSystem',
    component: InstitutionSystem,
    meta: {
      // 页面标题title
      title: '机构养老_机构养老解决方案_数字养老系统_木子科技',
      content: {
        keywords: '机构养老,机构养老解决方案,机构养老系统',
        description: '木子科技为养老院，敬老院，护理院，福利院，养老公寓等机构提供机构养老管理系统。为机构降低成本，提高运营效率等提供解决方案。'
      }
    }
  },
  {
    path: '/system/family_nursing_bed',
    name: 'FamilyNursingBed',
    component: FamilyNursingBed,
    meta: {
      // 页面标题title
      title: '家庭养老床位_家庭养老床位解决方案_数字养老系统_木子科技',
      content: {
        keywords: '家庭养老床位,家庭养老床位解决方案,家庭养老床位系统',
        description: '木子科技家庭床位系统，解决以下常见问题：家庭养老床位、家庭养老床位方案、养老家庭床位、家庭养老床位的规范标准、家庭养老照护床位、家庭养老照护床位申请、家庭养老床位标准、家庭养老床位项目计划、家庭养老床位困难、家庭养老院床位、如何申请家庭养老床位、家庭养老护理型床位等。'
      }
    }
  },
  {
    path: '/system/meal',
    name: 'MealSystem',
    component: MealSystem,
    meta: {
      // 页面标题title
      title: '养老助餐_养老助餐解决方案_老人食堂|数字养老系统_木子科技',
      content: {
        keywords: '养老助餐,养老助餐解决方案,养老助餐解决方案,老人食堂',
        description: '木子科技为老年人提供餐饮服务的系统。该系统通过数字化、实现老年人点餐、送餐、结算等全流程的自动化，优质的餐饮服务，提高老年人的生活品质'
      }
    }
  },
  {
    path: '/system/visit',
    name: 'VisitSystem',
    component: VisitSystem,
    meta: {
      // 页面标题title
      title: '探视走访_探视走访系统_数字养老系统_木子科技',
      content: {
        keywords: '探视走访,关爱老人探视走访解决方案,探视走访系统',
        description: '木子科技为空巢独居困难老年人探访关爱“安全守护”解决方案'
      }
    }
  },

  {
    path: '/system/elderly_transform',
    name: 'ElderlyTransformSystem',
    component: ElderlyTransformSystem,
    meta: {
      // 页面标题title
      title: '适老化改造_适老化改造系统_数字养老系统_木子科技',
      content: {
        keywords: '适老化改造,适老化改造解决方案,适老化改造系统',
        description: '居家适老化改造是一项综合的居家养老服务，是我国养老服务体系中的重要一环。为满足绝大多数老人居家养老需求，适老化改造被多地纳入民生实事，建立适老化改造系统，建立适老化改造一户一档，对改造实施全流程监管，提高改造服务质量，有效提升老年人的幸福感、获得感、安全感'
      }
    }
  },

  {
    path: '/system/disabled_elder_help',
    name: 'DisabledElderHelpSystem',
    component: DisabledElderHelpSystem,
    meta: {
      // 页面标题title
      title: '失能老人帮扶_失能老人帮扶系统_数字养老系统_木子科技',
      content: {
        keywords: '失能老人帮扶,失能老人帮扶解决方案,失能老人帮扶系统',
        description: '木子科技提供失能老人帮扶解决方案'
      }
    }
  },

  {
    path: '/device',
    name: 'Device',
    component: Device,
    meta: {
      // 页面标题title
      title: '养老产品_养老设备_木子科技-数字养老系统',
      content: {
        keywords: '养老产品,养老设备,居家养老,社区养老,机构养老,适老化改造',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },
  {
    path: '/case',
    name: 'Case',
    component: Case,
    meta: {
      // 页面标题title
      title: '数字养老案例_智慧养老案例_木子科技-数字养老系统',
      content: {
        keywords: '数字养老案例,智慧养老案例',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      // 页面标题title
      title: '木子科技-数字养老系统',
      content: {
        keywords: '居家养老,社区养老,机构养老,适老化改造,关爱老人探视走访,失能老人帮扶,老年人助餐',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply,
    meta: {
      // 页面标题title
      title: '申请养老系统_木子科技-数字养老系统',
      content: {
        keywords: '申请居家养老,社区养老,机构养老,适老化改造,关爱老人探视走访,失能老人帮扶,老年人助餐',
        description: '木子信息科技是一家专注于智慧养老系统服务领域的高新技术企业，提供智慧养老系统解决方案，为居家养老、社区养老、机构养老、适老化改造、关爱老人探视走访、失能老人帮扶、老年人助餐、家政公司、养老地产提供智慧平台+硬件设备+运营支持的一站式服务'
      }
    }
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      title: '协议-风筝缘'
    }
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})

export default router
