<template>
  <div class="role">
    <el-row class="role-row" >
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/government-supervisors.png"
            alt=""
          />
          <div class="role-name">政府监管人员</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/grass-roots-cadres.png"
            alt=""
          />
          <div class="role-name">基层干部</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/Evaluator.png"
            alt=""
          />
          <div class="role-name">评估人员</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>

      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/housekeeping-staff.png"
            alt=""
          />
          <div class="role-name">家政人员</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/Volunteer.png"
            alt=""
          />
          <div class="role-name">机构人员</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="role-item">
          <img
            class="role-icon"
            src="../../images/smart-elderly/government-supervisors.png"
            alt=""
          />
          <div class="role-name">志愿者</div>
          <div class="white-line"></div>
          <div class="role-desc">
            这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。这里是描述，需要补充。
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.role{
  padding-bottom 120px;
}
.role-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom 30px;

  .role-icon {
    width: 63px;
    height: 65px;
  }

  .role-name {
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .white-line {
    width: 64px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 18px;
  }

  .role-desc {
    // padding: 0 50px;
    text-align: left;
    width: 333px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 18px;
  }
}

.role-row {
  margin-top: 110px;
  .el-col{
    margin-top 25px;
  }
}
</style>
