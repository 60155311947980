<template>
  <div class="certification">
    <el-row class="certification-row">
      <el-col v-for="item in 4" :key="item" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="certification-item">
          <img
            class="certification-item-img"
            src="../../images/smart-elderly/certificate.png"
            alt=""
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='stylus' rel='stylesheet/stylus'>
.certification{
    // padding: 125px 0 165px 0;
    max-width: 1240px;
    margin: 0 auto;
}
.certification-row{
  padding-top 125px;
  .el-col{
    margin: 0 auto;
  }
}
.certification-item-img {
  width: 280px;
  height: 374px;
}
</style>
