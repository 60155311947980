<template>
  <div style="width: 100%" class="page-content">
    <!-- 轮播图 -->
    <div class="banner-matter">
      <el-carousel height="465px">
        <el-carousel-item v-for="item in 1" :key="item">
          <el-image
            class="index-banner-img"
            :src="require('../images/smart-elderly/index-banner.png')"
            alt=""
            fit="cover"
          />
          <div class="index-banner-txt-box hidden-md-and-down">
            <p class="index-banner-txt-title">智慧养老管理系统标准版</p>
            <p class="index-banner-txt-desc">
              提供完整的社区养老系统，居家养老系统，机构养老系统，以标准化流程和系统的管理
              实现养老机构的高效运转。
            </p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 养老行业市场规模 -->
    <div class="market-matter">
      <div class="col-center module-title">养老行业市场规模10万亿</div>
      <div class="col-center module-title-desc">
        我们为您提供专业的智慧养老方案，致力于打造国内顶尖的互联网+智慧养老行业
      </div>
      <market class="market"></market>
    </div>
    <!-- 养老行业全场景覆盖 -->
    <div class="scene-matter">
      <div class="col-center module-title">养老行业全场景覆盖</div>
      <div class="col-center module-title-desc">
        一站式智慧养老服务 ● 覆盖全国100+个地市 ● 1000+养老服务单位的选择
      </div>
      <scene class="col-center"></scene>
    </div>
    <!-- 养老行业多元化解决方案 -->
    <div class="col-center solution">
      <div class="col-center module-title">养老行业多元化解决方案</div>
      <div class="col-center module-title-desc">
        资深研发团队专注智慧养老系统开发，技术更放心，服务有保障，您放心做市场无后顾之忧
      </div>
      <solution></solution>
    </div>
    <!-- 我们的优势 -->
    <div class="col-center advantage">
      <div class="col-center module-title">我们的优势</div>
      <div class="col-center module-title-desc">
        创新，我们从未停止，一切以用户价值为依归
      </div>
      <advantage class="col-center"></advantage>
    </div>
  </div>
</template>
<script>
import scene from "@/components/page-home/scene.vue";
import advantage from "@/components/page-home/AdvantageItem.vue";
import solution from "@/components/page-home/solution-item.vue";
import market from "@/components/page-home/MarketItem.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  components: {
    scene,
    advantage,
    solution,
    market,
  },
  data() {
    return {
      bannerList: ["https://img.cxzai.com/fzy/banner/banner.jpg"],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}
.page-content {
  padding-bottom: 150px;
  background: #FFF;
  //轮播图左右点击按钮
  .el-carousel__arrow{
    border-radius 0 !important;
  }
  // 首页轮播图
  .banner-matter {
    width: 100%;
    //height: 465px;
    .index-banner-img {
      width: 100%;
      height 100%;
    }
    .index-banner-txt-box {
      animation: index-banner-txt-box 0.5s ease-out both;
    }
    @keyframes index-banner-txt-box {
      0% {
        transform: translateX(-120%)
      }
      100% {
        transform: translateX(-90%)
      }
    }
    .index-banner-txt-box {
      //width: 1440px;
      height: 531px;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translate(-50%, 0%);
      padding-left: 157px;
      box-sizing: border-box;

      .index-banner-txt-title {
        font-size: 60px;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 2px 2px 2px rgba(86, 86, 86, 0.5);
      }

      .index-banner-txt-desc {
        width: 684px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25px;
        text-shadow: 2px 2px 2px rgba(86, 86, 86, 0.5);
        margin-top: 36px;
      }
    }
  }
  //  模块标题
  .module-title {
    font-size: 36px;
    font-weight: 500;
    color: #000530 !important;
    line-height: 50px;
  }

  .module-title-desc {
    font-size: 16px;
    color: #666666;
    line-height: 22px;
    margin-top: 20px !important;
  }
  //市场规模
  .market-matter {
    padding-bottom: 150px;
    margin-top 65px;

    .market{
      margin-top 60px;
    }
  }
  //解决方案
  .solution {
    background: #fff;
    padding:150px 0 !important;
  }
  //场景
  .scene-matter {
    padding: 40px 0 100px 0;
    background: #F9FAFF;
  }
//  我们的优势
  .advantage{
    margin-top 60px;
  }

}
</style>
