<template>
  <div class="more-role">
    <div>
      <el-row >
        <el-col :xs="8" :sm="6" :md="8" :lg="8" :xl="8" class="hidden-md-and-down">
          <div class="circle-img">
            <img class="circle-img-small" src="../../images/smart-elderly/city-1.png" alt="" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="circle-img">
            <img class="circle-img-big" src="../../images/smart-elderly/city-2.png" alt="" />
          </div>
        </el-col>
        <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="8" class="hidden-md-and-down">
          <div class="circle-img">
            <img class="circle-img-small" src="../../images/smart-elderly/city-3.png" alt="" />
          </div>
        </el-col>
      </el-row>
      <div class="title">政府基层工作者掌握老人数据</div>
      <div class="content">
        这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，
        这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，这里的内容需要调整，
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/display.css';
export default {
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>

<style lang='stylus' rel='stylesheet/stylus'>
.more-role {
  .el-row{
    padding 0 200px;
  }
  padding-bottom: 150px;
  .circle-img {
    width: 100%;
    height: 100%;
    margin-top: 56px;

    .circle-img-small {
      margin-top: 50px;
      width: 210px;
      height: 210px;
      border-radius: 50%;
    }

    .circle-img-big {
      width: 300px;
      height: 300px;
      border-radius: 50%;
    }
  }

  .title {
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    margin-top: 50px;
  }

  .content {
    text-align: left;
    font-size: 22px;
    color: #666666;
    line-height: 40px;
    //padding: 0 134px 0 163px;
    max-width 1143px;
    margin 0 auto;
    margin-top: 50px;
    padding 0 20px;

  }
}
@media screen and (max-width: 992px) {
  .more-role {
    .el-row {
      padding 0;
    }
  }
}
</style>
