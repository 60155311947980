<template>
  <div class="culture">
    <el-row class="culture-row" :gutter="50" style="margin: 0 auto">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="culture-item">
          <div class="blue-line"></div>
          <img
            class="culture-item-icon"
            src="../../images/smart-elderly/mission.png"
            alt=""
          />
          <div class="culture-item-title">使命</div>
          <div class="culture-item-desc">
            让中国养老不再难！
            （致力于养老产业建设、规划养老服务秩序、用科技让中国养老不再难）
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="culture-item">
          <div class="blue-line"></div>
          <img
            class="culture-item-icon"
            src="../../images/smart-elderly/vision.png"
            alt=""
          />
          <div class="culture-item-title">愿景</div>
          <div class="culture-item-desc">
            做一家养老行业的领军企业
            为客户创造价值、为员工谋取幸福、为家庭解决难题、为社会贡献力量
            到2030年服务5000万户家庭、帮助10万养老组织或者企业实现赢利
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="culture-item">
          <div class="blue-line"></div>
          <img
            class="culture-item-icon"
            src="../../images/smart-elderly/value.png"
            alt=""
          />
          <div class="culture-item-title">价值观</div>
          <div class="culture-item-desc">
            诚信担当 钻研创新 成人达己 知行合一
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='stylus' rel='stylesheet/stylus'>
.culture-row {
  // padding: 0 50px;
  box-sizing: border-box;
  .el-col{
    margin-top 40px;
  }
}

.culture {
  max-width: 1240px;
  margin: 0 auto;

  .culture-item {
    box-sizing: border-box;
    // width: 381px;
    width: 100%;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 2px #E5E5E5;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;

    .blue-line {
      width: 100%;
      height: 14px;
      background: #4190FF;
      border-radius: 8px 8px 0px 0px;
    }

    .culture-item-icon {
      width: 177px;
      height: 177px;
      margin-top: 53px;
      border-radius: 50%;
    }

    .culture-item-title {
      font-size: 26px;
      font-weight: 500;
      color: #000530;
      line-height: 37px;
      margin-top: 63px;
    }

    .culture-item-desc {
      padding: 0 22px;
      font-size: 16px;
      color: #737C8B;
      line-height: 22px;
      margin-top: 22px;
    }
  }

  .culture-item:hover>.culture-item-icon {
    transform: rotate(360deg);
    transition: transform 0.5s linear;
  }

  .culture-item:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-in-out;
  }
}
</style>
