<template>
  <div class="advantage-box">
    <el-row :gutter="40">
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="advantage-item">
          <el-image
            class="advantage-item-img"
            :src="require('../../images/smart-elderly/advantage-express.png')"
            alt=""
            fit="cover"
          />
          <div class="advantage-item-title">
            <div class="red-line" />
            政策大力支持
          </div>
          <div class="advantage-item-content">
            全国各级政府部门纷纷出台各项养老政策，积极推动养老信息化、智能化的发展，并已成为国家“十三五”发展建设规划的重要内容，这标志着“互联网+养老”行动的全面开始。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="advantage-item">
          <el-image
            class="advantage-item-img"
            :src="require('../../images/smart-elderly/advantage-polly.png')"
            alt=""
            fit="cover"
          />
          <div class="advantage-item-title">
            <div class="red-line" />
            涉老经验丰富
          </div>
          <div class="advantage-item-content">
            7年的探索实践，8种养老业态的需求认可，10场养老论点的专业阐述，15个特色场景的智能化方案，212家的应用落地。
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="advantage-item">
          <el-image
            class="advantage-item-img"
            :src="require('../../images/smart-elderly/advantage-team.png')"
            alt=""
            fit="cover"
          />
          <div class="advantage-item-title">
            <div class="red-line" />
            精湛的技术团队
          </div>
          <div class="advantage-item-content">
            技术精湛的研发团队，智慧化的创新思维，专业的网络运维团队，周到细致的培训指导。移动智慧化的用户体验，人工智能化的场景应用，数据集中化的可视分析。
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.advantage-box {
  padding 0 60px 0 100px;
  box-sizing border-box;
  .advantage-item {
    //width: 388px;
    height: 424px;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px 0px rgba(11, 11, 11, 0.08);
    margin-top: 48px;

    .advantage-item-img {
      display: block;
      width: 100%;
      height: 202px;
    }

    .advantage-item-title {
      font-size: 20px;
      color: #000053;
      display: flex;
      align-items: center;
      padding: 0 30px;
      margin-top: 36px;
      transform: scale(1.0);
    transition: transform 0.5s ease-in-out;
      .red-line {
        width: 52px;
        height: 4px;
        background: #FF39CA;
        margin-right: 10px;
      }
    }

    .advantage-item-content {
      font-size: 16px;
      color: #AAAAAA;
      line-height: 22px;
      padding: 0 30px;
      margin-top: 12px;
      text-align: left;
      transform: scale(1.0);
    transition: transform 0.5s ease-in-out;
    cursor pointer;
    }
  }
  .advantage-item:hover>.advantage-item-title{
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
    color:#FFF;
  }
  .advantage-item:hover{
    background-color:rgba(0,0,0,.2)
    color:#FFF !important;
  }
  .advantage-item:hover .advantage-item-content{
    color:#FFF !important;
  }
}
@media screen and (max-width: 992px) {
  .advantage-box{
    padding 0 10px;
  }
  }
</style>
