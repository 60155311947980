var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-us-contain"},[_c('el-image',{staticClass:"about-us-head-img",attrs:{"src":require('../images/smart-elderly/aboutUs-banner.png'),"alt":"","fit":"cover"}}),_vm._m(0),_c('div',{staticClass:"col-center about-us"},[_c('div',{staticClass:"about-module-title"},[_vm._v("关于我们")]),_vm._m(1),_c('about')],1),_c('div',{staticClass:"col-center culture-matter"},[_c('div',{staticClass:"about-module-title"},[_vm._v("企业文化")]),_vm._m(2),_c('culture',{staticClass:"culture-box"})],1),_c('div',{staticClass:"col-center certification-matter"},[_c('div',{staticClass:"about-module-title"},[_vm._v("企业资质")]),_vm._m(3),_c('certification')],1),_c('div',{staticClass:"col-center connect-matter"},[_c('div',{staticClass:"about-module-title"},[_vm._v("联系我们")]),_vm._m(4),_c('baidu-map',{staticClass:"ditu",attrs:{"scroll-wheel-zoom":true,"center":_vm.location,"zoom":_vm.zoom,"markers":_vm.markers,"ak":"sOwhibZUh1YpYUfDiA8cM27isU6b7Yhw"}},[_c('bm-view',{staticStyle:{"width":"100%","height":"500px","flex":"1"}}),_c('bm-marker',{attrs:{"position":{ lng: _vm.locationData.lng, lat: _vm.locationData.lat },"dragging":false,"content":"22222222222222222","animation":"BMAP_ANIMATION_BOUNCE"}},[_c('bm-label',{attrs:{"content":"地址: 合肥市包河区万达广场7号楼2602","labelStyle":{
            color: '#333',
            border: 'none',
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '8px ',
          },"offset":{ width: '0', height: 30 }}})],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-us-banner-txt-box hidden-md-and-down"},[_c('p',{staticClass:"about-us-banner-txt-title"},[_vm._v("致力于打造智慧养老品牌")]),_c('p',{staticClass:"about-us-banner-txt-desc"},[_vm._v(" 以质量求生存、以信誉求发展、以服务得结果 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-line-long"},[_c('div',{staticClass:"grey-line-short"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-line-long"},[_c('div',{staticClass:"grey-line-short"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-line-long"},[_c('div',{staticClass:"grey-line-short"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-line-long"},[_c('div',{staticClass:"grey-line-short"})])
}]

export { render, staticRenderFns }