<template>
  <div>
    <div class="office-module-title">数字化养老</div>
    <el-row  class="digital-providing-aged">
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
        <img src="../../images/smart-elderly/mobbile.png" alt="" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
        <div class="digital-providing-aged-content">
          <div class="content-box">
            <div class="office-blue-block">1</div>
            <p class="title">养老数字化</p>
            <p class="content">
              丢弃繁杂的纸质文档，让养老数据动起来，活起来，让老年人养老更加智能，快速、便捷
            </p>
          </div>
          <div class="content-box">
            <div class="office-blue-block">2</div>
            <p class="title">简单、易用</p>
            <p class="content">
              解决工作者不会用、用不起来的问题。让每一位工作者及用户都能够轻松使用的移动养老服务平台
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="office-module-title">互联互通</div>
    <el-row  class="digital-providing-aged">
      <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
        <div class="digital-providing-aged-content interconnection">
          <div class="content-box">
            <div class="office-blue-block">1</div>
            <p class="title">多端数据云端互通</p>
            <p class="content">
              组建政府、用户、机构、平台四端通过云端互通数据，通过硬件获取老人基本档案、健康信息、手表定位等，提高了对服务数据及事务处理的高效性和准确性，也保证了各角色间的高效沟通
            </p>
          </div>
          <div class="content-box">
            <div class="office-blue-block">2</div>
            <p class="title">数据实时查看</p>
            <p class="content">
              提供海量移动APP运营数据，助力企业、投资者洞察竞争格局，发现行业机会
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
        <img src="../../images/smart-elderly/mobbile-2.png" alt="" />
      </el-col>
    </el-row>

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.office-module-title {
  padding: 53px 0;
  max-width: 1440px;
  border-bottom: 1px solid #dddddd;
  font-size: 35px;
  font-weight: 500;
  color: #000530;
}

.digital-providing-aged {
  max-width: 1440px;
  //padding: 0 140px;
  margin-top: 58px;

  img {
    width: 373px;
    height: 385px;
  }

  .office-blue-block {
    width: 28px;
    height: 28px;
    background: linear-gradient(360deg, #80A8FF 0%, #41ACFF 100%);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: -67px;
    text-align: center;
    line-height: 28px;
    color: #Fff;
    font-size: 14px;
  }

  .digital-providing-aged-content {
    //width: 616px;
    //margin-left: 170px;
    padding-left: 90px;

    .content-box {
      position: relative;
    }

    .content-box:nth-child(2) {
      margin-top: 37px;
    }

    .title {
      text-align: left;
      font-size: 20px;
      color: #3D4147;
      line-height: 28px;
      margin: 0 !important;
    }

    .content {
      //width: 549px;
      text-align: left;
      //width: 549px;
      font-size: 16px;
      color: #737C8B;
      line-height: 22px;
      margin-top: 10px !important;
    }
  }

  .interconnection {
    padding: 0;
    margin-left: 80px;

    img {
      width: 350px;
      height: 387px;
    }
  }
}
</style>
