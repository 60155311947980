<template>
  <div>
    <div class="centerContent footer-box">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="foot-obj-title">产品方案</div>
          <div class="foot-obj-content">
            <ul v-for="(item, index) in solutionList" :key="index">
              <li
                v-for="(it, index2) in item.childList"
                :key="index2"
                @click="onClickToNextPage(it)"
              >
                {{ it.name }}
              </li>
              <!--              <li>机构养老</li>-->
              <!--              <li>社区养老</li>-->
              <!--              <li>养老助餐</li>-->
            </ul>
            <!--            <ul>-->
            <!--              <li>适老化改造</li>-->
            <!--              <li>老人探视走访</li>-->
            <!--              <li>失能老人帮扶</li>-->
            <!--              <li>家庭养老床位</li>-->
            <!--            </ul>-->
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
          <div class="foot-obj-title">关于我们</div>
          <div class="foot-obj-content">
            <ul>
              <li @click="onClickToPage('About')">公司简介</li>
              <li @click="onClickToPage('Apply')">免费试用</li>
              <li>客服电话：400-763-5656</li>
              <li>地址：安徽省合肥市包河区万达广场7号楼2602</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="5" :lg="5" :xl="5">
          <div class="footer-img-box">
            <div class="footer-img">
              <el-image
                class="ercode"
                fit="contain"
                :src="require('/src/images/smart-elderly/wechat.png')"
              ></el-image>
              <div class="ercode-title">微信小程序</div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="5" :lg="5" :xl="5">
          <div class="footer-img-box">
            <div class="footer-img">
              <el-image
                class="ercode"
                fit="contain"
                :src="
                  require('/src/images/smart-elderly/wechat-technology.png')
                "
              ></el-image>
              <div class="ercode-title">技术支持微信</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="foot-text">
        Copyright © 2023 安徽木子信息技术有限公司 版权所有
        皖ICP备19007012号-1</el-row
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FootView",
  data() {
    return {
      solutionList: [
        {
          childList: [
            { name: "居家养老", routeName: "system/home" },
            { name: "社区养老", routeName: "system/community" },
            { name: "机构养老", routeName: "system/institution" },
            { name: "家庭养老床位", routeName: "system/family_nursing_bed" }
          ],
        },
        {
          childList: [
            { name: "养老助餐", routeName: "system/meal" },
            { name: "老人探视走访", routeName: "system/visit" },
            { name: "适老化改造", routeName: "system/elderly_transform" },
            { name: "失能老人帮扶", routeName: "system/disabled_elder_help" }
          ],
        },
      ],
    };
  },
  created() {},

  methods: {
    onClickToPage(item) {
      if (item === "About") {
        this.activeIndex = 4;
      } else if (item === "Apply") {
        this.activeIndex = 99;
      }
      let routeName = this.$route.name;
      if (routeName === item) {
        return;
      } else {
        let route = "/" + item;
        this.$router.push(route).catch((err) => {});
        this.$emit("changeBarStatus");
      }
    },
    onClickToNextPage(item) {
      let route = item.routeName ? "/" + item.routeName : "";
      this.$router.push(route).catch((err) => {});

    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">

.footer-box {
  min-height: 400px;
  padding: 70px 20px 0 70px !important;
  .foot-obj-title {
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    text-align left;
  }

  .foot-obj-content {
    display: flex;
    ul {
      padding: 0;
      padding-right 32px;
      li {
        font-size: 16px;
        color: #888888;
        margin-top: 16px;
        cursor: pointer;
      }
    }

  }

  .footer-img-box {
    text-align center;
    .footer-img {
      display: inline-block;
      padding-top: 28px;
      width: 202px;
      height: 235px;
      background: #484848;
      text-align: center;
      box-sizing: border-box;

      .ercode {
        width: 159px;
        height: 159px;
      }

      .ercode-title {
        margin-top: 8px;
        font-size: 16px;
        color: #888888;
      }
    }

    .footer-img:nth-child(2) {
      margin-left: 20px;
    }
  }
}

.foot-text {
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  .footer-box{
    padding 0 !important;
    .foot-obj-title{
      text-align center;
      margin-top 30px !important;
    }
    .footer-img-box{
      margin-top 30px !important;

    }
    ul{
      margin 0 auto;
    }
  }
}
</style>
