<template>
  <div class="apply">
    <el-image
      class="apply-banner"
      :src="require('../images/smart-elderly/aboutUs-banner.png')"
      alt=""
      fit="cover"
    />
    <!-- bannner 图上的文字 -->
    <div class="about-us-banner-txt-box">
      <p class="about-us-banner-txt-title">致力于打造智慧养老品牌</p>
      <p class="about-us-banner-txt-desc">
        以质量求生存、以信誉求发展、以服务得结果
      </p>
    </div>
    <div class="col-center apply-matter">
      <div class="module-title">平台免费试用申请</div>
      <div class="grey-line-long">
        <div class="grey-line-short" />
      </div>
      <el-row class="apply-row" :gutter="50">
        <el-col
          :xs="24"
          :sm="24"
          :md="11"
          :lg="11"
          :xl="11"
          class="hidden-md-and-up"
        >
          <el-image
            class="right-hellow-img"
            :src="require('../images/smart-elderly/hellow.png')"
            alt=""
            fit="cover"
          />
        </el-col>
        <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13">
          <div class="apply-info-box" >
            <div class="apply-left-title">
              请留下您的联系方式，我们将为您安排平台免费试用！
            </div>
            <div class="apply-input">
              姓名:<input v-model="name"  placeholder="请输入姓名" />
            </div>
            <div class="apply-input">
              电话:<input  v-model="phone" placeholder="请输入电话" maxlength="11" />
            </div>
            <div class="apply-btn" ><button  @click="onClickSubmit">提交</button></div>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="11"
          :lg="11"
          :xl="11"
          class="hidden-sm-and-down"
        >
          <el-image
            class="right-hellow-img"
            :src="require('../images/smart-elderly/hellow.png')"
            alt=""
            fit="cover"
          />
        </el-col>
      </el-row>
      <el-row class="apply-row-2" :gutter="10">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
          class="hidden-sm-and-down"
        >
          <img
            class="apply-left-service-img"
            src="../images/smart-elderly/service-people.png"
            alt=""
        /></el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="apply-right-title">
            或通过微信咨询客服,24h在线接待高效咨询
          </div>
          <div class="apply-right-service">
            <img src="../images/smart-elderly/ercode-service.png" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import "element-ui/lib/theme-chalk/display.css";
import request, { httpGet } from '../http/request'
export default {
  data() {

    return {
      name: '',
      phone: ''
    };
  },
  created() {

  },
  methods: {


    validatePhone(value) {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return '手机号不能为空';
      }
      if (!Number.isInteger(+value)) {
        return  '手机号格式不正确';
      } else {
        if (phoneReg.test(value)) {
          return  null;
        } else {
          return  '手机号格式不正确';
        }
      }
    },

    onClickSubmit(){

      if (!this.name){
        this.$message({
          message: '姓名不能为空',
          type: 'error',
          offset: 80
        })
        return;
      }

      let vPhone =  this.validatePhone(this.phone);
      if (vPhone){
        this.$message({
          message: vPhone,
          type: 'error',
          offset: 80
        })
        return;
      }

      // var params = {
      //   name:this.name,
      //   phone: this.phone
      // }
      var params = {
        position:1
      }
      request.httpPost('/v1/common/ad', params, success => {
        this.$message({
          message: '提交成功，24小时内容，工作人员将联系您，请保持手机畅通',
          type: 'success',
          offset: 80
        })
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}
.apply {
  width: 100%;
  background: #fff;
  position relative;
  //顶部图
  .apply-banner {
    width: 100%;
    height: 563px;
  }
  //顶部图 上 文字
  .about-us-banner-txt-box{
    //opacity: 0; /** 初始状态为不可见 */
    animation: 2s fade-in ease-out; /** 动画属性 */
  }
  @keyframes fade-in{
    0% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .about-us-banner-txt-box {
    width: 1440px;
    height: 531px;
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translate(-50%, 0%);
    //padding-left: 157px;
    box-sizing: border-box;
    text-align: center;

    .about-us-banner-txt-title {
      font-size: 50px;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 139px;
    }

    .about-us-banner-txt-desc {
      padding: 0;
      margin: 0;
      font-size: 22px;
      color: #FFFFFF;
      line-height: 25px;
    }
  }
  //标题
  .grey-line-long {
    max-width: 1240px;
    margin: 0 auto;
    border-bottom: 2px solid #BEC5D1;
    margin-top: 40px;
    position: relative;
    .grey-line-short {
      width: 256px;
      height: 14px;
      background: #BEC5D1;
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
//  联系方式
  .apply-row {
    box-size:border-box;
    margin-top:60px;
  }
  .apply-matter {
    padding: 70px 0 150px 0;
    .module-title{
      font-size: 35px;
      font-weight: 500;
      color: #000530;
    }
    .apply-left-title {
      font-size: 26px;
      color: #000530;
      margin-top: 48px;
      text-align: left;
    }

    .apply-input {
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      color: #000530;

      input {
        width: 274px;
        height: 38px;
        border-radius: 8px;
        border: 1px solid #999999;
        margin-left: 30px;
        text-align: center;
        margin-top: 55px;
        font-size: 16px;
        outline: none;
      }

      input:nth-child(1) {
        margin-top: 65px;
      }
    }

    .apply-btn {
      text-align: left;
      margin-top: 55px;

      button {
        width: 352px;
        height: 38px;
        background: #417DFF;
        border-radius: 8px;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        line-height: 38px;
        outline: none;
        border: none;
        transition: width 0.5s ease, height 0.5s ease;
      }

      button:hover {
        font-size: 18px;
        scale: 1.05;
        transition: width 0.5s ease, height 0.5s ease;
      }
    }

    .right-hellow-img {
      max-width: 540px;
      height: auto;
      //margin-right:100px;
    }
    .apply-info-box{
      padding-left:100px;
    }

  }

//  客服微信
  .apply-row-2 {
    margin-top: 200px;
  }

  .apply-left-service-img {
    width: 575px;
    height: 442px;
    margin-top: 20px;
    //margin-left:100px;
  }

  .apply-right-title {
    text-align: center;
    font-size: 26px;
    color: #000530;
  }

  .apply-right-service:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;

  }

  .apply-right-service {
    width: 249px;
    height: 256px;
    border: 2px #999 dashed;
    text-align: center;
    line-height: 256px;
    margin: 0 auto;
    margin-top: 77px;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;

    img {
      width: 236px;
      height: 243px;
      margin-top: 6px;
    }
  }

}
@media screen and (max-width: 992px) {
  .apply{
    .apply-banner{
      height 300px;
    }
  }
  .apply-info-box{
    padding 0 10px !important;
    .apply-left-title{
      text-align center !important;
    }
    .apply-input{
      text-align center !important;
      margin-top 20px !important;
      input{
        margin-top 20px !important;
      }
    }
    .apply-btn{
      text-align center !important;
      margin-top 50px !important;

    }

  }
  .apply-row-2{
    margin-top 50px !important;
    .apply-right-service{
      margin-top 20px;
    }
  }


}
</style>
