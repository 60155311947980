<template>
  <div :style="{ background: `rgba(255,255,255,${scrollTop / 64})` }">
    <div class="col-center navBar" @scroll="handleScroll">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
          <div class="logo-content" @click="onclickHome">
            <el-image
              class="logo"
              v-if="scrollTop >= 64"
              :src="require('../images/smart-elderly/logo.png')"
              alt=""
              fit="cover"
            />
            <el-image
              v-else
              class="logo"
              :src="require('../images/smart-elderly/logo-white.png')"
              alt=""
              fit="cover"
            />
          </div>
        </el-col>
        <el-col
          :xs="0"
          :sm="6"
          :md="13"
          :lg="13"
          :xl="13"
          class="hidden-sm-and-down"
        >
          <div class="head-bar">
            <ul class="head-bar-box">
              <li
                class="head-bar-item"
                v-for="(item, index) in barList"
                :key="index"
                @mouseenter="handleMouseEnter(item)"
                @mouseleave="handleMouseLeave(item)"
              >
                <span
                  :style="{
                    color:
                      activeIndex == index
                        ? '#FC822C'
                        : scrollTop === 0
                        ? '#FFF'
                        : '#333',
                  }"
                  @click="onClickToNextPage(item, index)"

                  >{{ item.name }}</span
                >
                <i
                  v-if="item.childList"
                  class="el-icon-arrow-down"
                  :style="{
                    color:
                      activeIndex == index
                        ? '#FC822C'
                        : scrollTop === 0
                        ? '#FFF'
                        : '#333',
                  }"
                ></i>
                <transition name="el-zoom-in-top">
                  <ul
                    @mouseenter="handleMouseEnter(item)"

                    :class="[
                      'system-item',
                      item.isMouseEnter
                        ? 'system-item-enter'
                        : 'system-item-not-enter',
                    ]"
                    v-if="item.childList"
                    ref="systemItem"
                  >
                    <li class="orange-block"></li>
                    <li
                      class="system-item-obj"
                      v-for="(it, index2) in item.childList"
                      :key="index2"
                      @click="onClickToNextPage(it, index)"
                    >
                      {{ it.name }}
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col
          :xs="0"
          :sm="6"
          :md="7"
          :lg="7"
          :xl="7"
          class="hidden-sm-and-down"
        >
          <div
            class="head-phone"
            :style="{ color: scrollTop === 0 ? '#FFF' : '#333' }"
          >
            热线电话:
            <img
              v-if="scrollTop >= 64"
              src="../images/smart-elderly/phone.png"
              alt=""
            />
            <img v-else src="../images/smart-elderly/phone-white.png" alt="" />
            <span :style="{ color: scrollTop === 0 ? '#FFF' : '#333' }"
              >400-763-5656</span
            >
          </div>
        </el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="0"
          :lg="0"
          :xl="0"
          class="dropdown-box hidden-md-and-up"
        >
          <el-dropdown
            @command="handleCommand"
            :hide-on-click="true"
            trigger="click"
          >
            <el-button size="small" class="menu-btn">
              <i class="el-icon-s-operation"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="">首页</el-dropdown-item>
              <el-dropdown-item command="system/home"
                >养老系统</el-dropdown-item
              >
              <el-dropdown-item command="Device">养老设备</el-dropdown-item>
              <el-dropdown-item command="office">掌上办公</el-dropdown-item>
              <el-dropdown-item command="about">关于我们</el-dropdown-item>
              <el-dropdown-item command="apply">申请试用</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import "element-ui/lib/theme-chalk/display.css";
import "element-ui/lib/theme-chalk/base.css";
import { getStore } from "@/utils/storage";
import constant from "@/constant";
export default {
  props: ["childEvent"],
  data() {
    return {
      Proportion: "",
      scrollTop: 0,
      activeIndex: 1, // 1
      // activeIndex2: "1",
      barList: [
        { name: "首页", routeName: "" },
        {
          name: "养老解决方案",
          routeName: "system/home",
          isMouseEnter: false,
          childList: [
            { name: "居家养老解决方案", routeName: "system/home" },
            { name: "社区养老解决方案", routeName: "system/community" },
            { name: "机构养老解决方案", routeName: "system/institution" },
            { name: "家庭养老床位解决方案", routeName: "system/family_nursing_bed" },
            { name: "养老助餐解决方案", routeName: "system/meal" },
            { name: "老人探视走访解决方案", routeName: "system/visit" },
            { name: "适老化改造解决方案", routeName: "system/elderly_transform" },
            { name: "失能老人帮扶解决方案", routeName: "system/disabled_elder_help" }
          ],
        },
        { name: "掌上办公", routeName: "office" },
        { name: "养老设备", routeName: "device" },
        {
          name: "关于我们",
          routeName: "about",
          isMouseEnter: false,
          childList: [
            { name: "关于木子", routeName: "about" },
            { name: "申请试用", routeName: "apply" },
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.routeName = this.$route.name;
    if (this.routeName === "About" || this.routeName === "Apply") {
      // 处理滚动事件的逻辑
      this.scrollTop = 0;
    } else {
      this.scrollTop = 64;
    }
    this.childEvent(this.routeName);
  },
  created() {
    this.handleNavBarStatus();
  },
  methods: {
    handleMouseEnter(item) {
      this.barList.forEach((res) => {
        res.isMouseEnter = false;
      });
      if (item.childList) {
        item.isMouseEnter = true;
      }
    },
    handleMouseLeave(item) {
      item.isMouseEnter = false;

    },
    // 点击返回首页
    onclickHome() {
      this.$router.replace("/");
    },
    // 滚轮事件
    handleScroll() {
      this.routeName = this.$route.name;
      if (this.routeName === "About" || this.routeName === "Apply") {
        // 处理滚动事件的逻辑
        this.scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      } else {
        return;
      }
    },
    // 小菜单点击事件
    handleCommand(command) {
      let route = command ? "/" + command : "";
      if (command == "") {
        this.$router.replace("/");
      } else {
        this.$router.push(route).catch((err) => {});
      }
      this._handleNavBar();
    },

    handleNavBarStatus() {
      this.token = getStore(constant.K_TOKEN);
      this.routeName = this.$route.name;
      // this.activeIndex2 = 99;
      if (this.routeName === "Index") {
        this.activeIndex = "0";
      } else if (this.routeName === "HomeSystem" || this.routeName === "CommonSystem") {
        this.activeIndex = "1";
        // if (this.routeName === "HomeSystem") {
        //   this.activeIndex2 = 0;
        // } else if (this.routeName === "CommonSystem") {
        //   this.activeIndex2 = 1;
        // }
      } else if (this.routeName === "Office") {
        this.activeIndex = "2";
      } else if (this.routeName === "Device") {
        this.activeIndex = "3";
      } else if (this.routeName === "About" || this.routeName === "Apply") {
        this.activeIndex = "4";
        this.scrollTop = 0;
      }
    },
    onClickToNextPage(item, index) {
      this.barList[index].isMouseEnter = false;
      this.activeIndex = index;
      let route = item.routeName ? "/" + item.routeName : "";
      this.$router.push(route).catch((err) => {});
      if (index == 0) {
        this.$router.replace("/");
      }
      this._handleNavBar();
    },
    // 点击 当是关于我们和申请使用的页面顶部状态栏是透明且逐渐变白
    _handleNavBar() {
      this.routeName = this.$route.name;
      if (this.routeName === "About" || this.routeName === "Apply") {
        // 处理滚动事件的逻辑
        this.scrollTop = 0;
      } else {
        this.scrollTop = 64;
      }
      this.childEvent(this.routeName);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}

.head-content {
  width: 100%;
  background: rgba(255, 255, 255, 0);
}

.navBar {
  padding-left: 100px;

  // logo
  .logo-content {
    height: 64px;
    line-height: 64px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left 20px;
    cursor pointer;
    .logo {
      display: flex;
      justify-content: left;
      align-items: center;
      max-width: 185px;
      height: auto;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
  }

  // 电话
  .head-phone {
    display: flex;
    align-items: center;
    padding: 17px 0;

    img {
      width: 23px;
      height: 24px;
      margin: 0 10px 0 10px;
    }

    img:hover+span {
      transform: scale(1.1);
      transition: transform 0.5s ease-in-out;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      color: #2B5AC5;
      cursor: pointer;
      transition: transform 0.5s ease-in-out;
    }

    span:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease-in-out;
    }
  }

  .head-bar {
    .head-bar-box {
      display: flex;
      position: relative;
      margin: 0;

      .head-bar-item {
        padding: 20px 20px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        position: relative;
      }

      .head-bar-item:hover span {
        color: #FC822C;
      }

      .head-bar-item:hover>.el-icon-arrow-down {
        transform: rotate(180deg);
        transition: transform 0.5s linear;
      }

      .el-icon-arrow-down {
        transform: rotate(0deg);
        transition: transform 0.5s linear;
        margin-left: 6px;
      }

      .head-bar-item:hover > .system-item {
        // transform: scale(1);
        // transition: transform 0.5s ease-in-out;
        // opacity: 1;
        // transition: all .3s ease;
        //display: block !important;
      }
      .system-item-enter{
        display block;
      }
      .system-item-not-enter{
        display none;
      }

      .system-item {
        //display: none;
        position: absolute;
        top: 64px;
        left: 0px;
        background: #fff;
        text-align: left;
        padding: 0px;
        margin: 0;
        border-radius: 3px 3px 0px 0px;
        box-sizing: border-box;
        padding-bottom: 20px;

        .orange-block {
          width: 210px;
          height: 6px;
          background: #FC822C;
          border-radius: 3px 3px 0px 0px;
        }

        .system-item-obj {
          margin-top: 20px;
          padding: 0 20px;
          box-sizing: border-box;
        }

        .system-item-obj:hover {
          color: #FC822C;
        }
      }
    }
  }

  .dropdown-box {
    height: 64px;
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;
    padding-right: 20px;
    box-sizing: border-box;

    .el-icon-s-operation {
      font-size: 18px;
    }

    .menu-btn {
      background: #2B5AC5;
      color: #FFF;
      margin-right 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .head-bar-item {
    padding: 20px 10px !important;
  }
}

@media screen and (max-width: 992px) {
  .logo {
    padding: 10px;
  }

  .navBar {
    padding: 0;
  }
}
</style>
