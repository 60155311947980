<template>
  <div class="solution-box">
    <div class="solution-title">
      <div class="blue-block" />
      智慧养老数据指挥中心打通各产业链体系
    </div>
    <div class="solution-elder-data">
      <el-image
        class="solution-elder-data-img"
        :src="require('../../images/smart-elderly/large-screen-picture.png')"
        alt=""
        fit="cover"
      />
    </div>
    <div class="solution-title">
      <div class="blue-block" />
      智慧养老App线上线下实时监护
    </div>
    <div class="app-info">
      <el-row :gutter="0">
        <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9">
          <div class="left-big-box">
            <el-image
              class="left-big-box-image"
              :src="
                require('../../images/smart-elderly/mini-program-index.png')
              "
              alt=""
              fit="cover"
            />
          </div>
        </el-col>
        <el-col
          :xs="8"
          :sm="6"
          :md="7"
          :lg="7"
          :xl="7"
          class="hidden-md-and-down"
        >
          <div class="right-info">
            <div class="right-info-small">
              <!-- <img src="../../images/smart-elderly/mini-program-2.png" alt="" /> -->
              <el-image
                class="right-info-small-img"
                :src="require('../../images/smart-elderly/mini-program-2.png')"
                alt=""
                fit="cover"
              />
            </div>
            <div class="right-info-small">
              <el-image
                class="right-info-small-img"
                :src="require('../../images/smart-elderly/mini-program-3.png')"
                alt=""
                fit="cover"
              />
            </div>
          </div>
        </el-col>
        <el-col
          :xs="8"
          :sm="6"
          :md="7"
          :lg="7"
          :xl="7"
          class="hidden-md-and-down"
        >
          <div class="right-info">
            <div class="right-info">
              <div class="right-info-small">
                <el-image
                  class="right-info-small-img"
                  :src="
                    require('../../images/smart-elderly/mini-program-4.png')
                  "
                  alt=""
                  fit="cover"
                />
              </div>
              <div class="right-info-small">
                <el-image
                  class="right-info-small-img"
                  :src="
                    require('../../images/smart-elderly/mini-program-5.png')
                  "
                  alt=""
                  fit="cover"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="solution-title">
      <div class="blue-block" />
      多端智能硬件设备供您选择
    </div>
    <div class="equipment-box">
      <el-row :gutter="10">
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="(item, index) in imgUrl"
          :key="index"
        >
          <div class="equipment-img-box">
            <el-image
              class="equipment-img"
              :src="item.url"
              alt=""
              fit="cover"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [
        "https://img.cxzai.com/fzy/banner/banner.jpg",
        "https://img.cxzai.com/fzy/banner/banner.jpg",
        "https://img.cxzai.com/fzy/banner/banner.jpg",
      ],
      // imgUrl: "https://mfs.ys7.com/mall/2d0f8d846a468094286db989874ae849.jpg",
      imgUrl: [
        {
          url: "https://mfs.ys7.com/mall/2d0f8d846a468094286db989874ae849.jpg",
        },
        {
          url: "https://mfs.ys7.com/mall/2d0f8d846a468094286db989874ae849.jpg",
        },
        {
          url: "https://mfs.ys7.com/mall/2d0f8d846a468094286db989874ae849.jpg",
        },
        {
          url: "https://mfs.ys7.com/mall/2d0f8d846a468094286db989874ae849.jpg",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">

.solution-box{
  padding-left 100px;
  //小标题（蓝色
  .solution-title {
    font-size: 20px;
    font-weight: 500;
    color: #417DFF;
    display: flex;
    align-items: center;
    margin-top: 50px;
    //padding-left: 100px;
    box-size: border-box;
    .blue-block {
      width: 24px;
      height: 24px;
      background: #417DFF;
      border-radius: 8px;
      margin-right: 24px;
    }
  }
  //产业链体系
  .solution-elder-data {
    margin-top: 45px;
    text-align: center;
    .solution-elder-data-img {
      max-width: 1252px;
      height: auto;
    }
  }
//  养老App
  .app-info {
    max-width: 1252px;
    margin: 0 auto;
    margin-top: 45px;
    // 左边大图
    .left-big-box {
      height: auto;
      background: linear-gradient(180deg, #F1F6FF 0%, #E8EFFF 100%);
      box-shadow: 0px 0px 8px 1px #E5F0FF;
      border-radius: 8px;
      padding: 58px 35px;
      .left-big-box-image {
        max-width: 366px;
        height: auto;
      }
    }
    .right-info {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;

      .right-info-small {
        width: 349px;
        height: 344px;
        background: linear-gradient(180deg, #F1F6FF 0%, #E8EFFF 100%);
        box-shadow: 0px 0px 8px 1px #E5F0FF, 0px 0px 8px 1px #E5F0FF;
        border-radius: 8px;
        margin: 0 auto;

        .right-info-small-img {
          width: 170px;
          height: 284px;
          margin-top: 30px;
        }
      }
    }

    .right-info-small:nth-child(2) {
      margin-top: 47px;
    }
  }
//  多种设备
  .equipment-box {
    padding: 0 10px;
    margin-top 40px;
    .equipment-img-box{
      max-width: 253px;
      height: 301px;
      border-radius: 8px;
      overflow: hidden;//关键部分
      margin 0 auto;
      margin-top: 10px;

      .equipment-img {
        max-width: 253px;
        height: 301px;
        border-radius: 8px;
        transform: scale(1.0);
        transition: transform 0.5s ease-in-out;
      }
      .equipment-img:hover{
        transform: scale(1.1);
        transition: transform 0.5s ease-in-out;
      }

    }

  }
}
@media screen and (max-width: 992px) {
.solution-box{
  padding 0 10px !important;
}
}
</style>
