<template>
  <div class="appoint-box">
    <el-row class="appoint-row" >
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="input-box1">
          <input class="appoint-input" v-model="name" placeholder="您的姓名" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div>
          <input
            class="appoint-input"
            v-model="phone"
            placeholder="您的联系方式"
            maxlength="11"
          />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="input-box">
          <button class="appoint-btn" type="success" @click="onClickSubmit">马上提交</button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import request  from '../../http/request'
export default {
  data() {
    return {
      name:'',
      phone:''
    };
  },
  created() {},
  methods: {

    validatePhone(value) {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return '手机号不能为空';
      }
      if (!Number.isInteger(+value)) {
        return  '手机号格式不正确';
      } else {
        if (phoneReg.test(value)) {
          return  null;
        } else {
        return  '手机号格式不正确';
        }
      }
    },

    onClickSubmit(){

      if (!this.name){
        this.$message({
          message: '姓名不能为空',
          type: 'error',
          offset: 80
        })
        return;
      }

      let vPhone =  this.validatePhone(this.phone);
      if (vPhone){
        this.$message({
          message: vPhone,
          type: 'error',
          offset: 80
        })
        return;
      }

      // var params = {
      //   name:this.name,
      //   phone: this.phone
      // }
      var params = {
        position:1
      }
      request.httpPost('/v1/common/ad', params, success => {
        this.$message({
          message: '提交成功，24小时内容，工作人员将联系您，请保持手机畅通',
          type: 'success',
          offset: 80
        })
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
  },
};
</script>

<style lang='stylus' rel='stylesheet/stylus'>
// 使用 /deep/
.appoint-input {
  width: 315px;
  height: 50px;
  background: #F2F2F2;
  box-shadow: 1px 2px 5px 0px rgba(116, 116, 116, 0.36);
  border-radius: 27px;
  border: none;
  text-align: center;
  font-size: 20px;
  border: 0;
  outline: none;
}

.appoint-btn {
  width: 315px;
  height: 54px;
  background: #417DFF;
  border-radius: 27px;
  border: 0;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
  transition: width 0.5s ease;
}

.appoint-btn:hover {
  width: 346px;
  font-size: 20px;
  transition: width 0.5s ease;
}

.appoint-box {
  padding-bottom: 160px;
}

.appoint-row {
  margin-top: 100px;
  .el-col{
    margin-top 30px;
  }
}

.input-box1 {
}

.input-box {

}
</style>
