<template>
  <div>
    <el-row class="device-row" :gutter="50">
      <el-col
        v-for="(item, index) in productList"
        :key="index"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="8"
      >
        <div class="device-item">
          <div class="device-item-title">{{ item.name }}</div>
          <div class="device-item-desc">
            {{ item.desc }}
          </div>
          <img class="device-item-img" :src="item.imgUrl" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  props: {
    productList: {
      type: Array,
      String: [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.device-row {
  padding: 0 130px;
  .el-col {
    margin-top: 40px;
  }
}



.device-item {
  width: 100%;
  background: #F4F7FA;
  box-shadow: 0px 6px 8px 2px rgba(116, 116, 116, 0.08);
  border-radius: 8px;
  padding: 47px 30px 50px 30px;
  box-sizing: border-box;

  .device-item-title {
    font-size: 22px;
    font-weight: 500;
    color: #000530;
  }

  .device-item-desc {
    font-size: 16px;
    color: #575E69;
    line-height: 22px;
    margin-top: 15px;
    text-align: left;
  }

  .device-item-img {
    width: 182px;
    height: 182px;
    margin-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .device-row {
    padding: 0 30px;
  }
}
</style>
