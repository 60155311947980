var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ background: `rgba(255,255,255,${_vm.scrollTop / 64})` })},[_c('div',{staticClass:"col-center navBar",on:{"scroll":_vm.handleScroll}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"xs":12,"sm":12,"md":4,"lg":4,"xl":4}},[_c('div',{staticClass:"logo-content",on:{"click":_vm.onclickHome}},[(_vm.scrollTop >= 64)?_c('el-image',{staticClass:"logo",attrs:{"src":require('../images/smart-elderly/logo.png'),"alt":"","fit":"cover"}}):_c('el-image',{staticClass:"logo",attrs:{"src":require('../images/smart-elderly/logo-white.png'),"alt":"","fit":"cover"}})],1)]),_c('el-col',{staticClass:"hidden-sm-and-down",attrs:{"xs":0,"sm":6,"md":13,"lg":13,"xl":13}},[_c('div',{staticClass:"head-bar"},[_c('ul',{staticClass:"head-bar-box"},_vm._l((_vm.barList),function(item,index){return _c('li',{key:index,staticClass:"head-bar-item",on:{"mouseenter":function($event){return _vm.handleMouseEnter(item)},"mouseleave":function($event){return _vm.handleMouseLeave(item)}}},[_c('span',{style:({
                  color:
                    _vm.activeIndex == index
                      ? '#FC822C'
                      : _vm.scrollTop === 0
                      ? '#FFF'
                      : '#333',
                }),on:{"click":function($event){return _vm.onClickToNextPage(item, index)}}},[_vm._v(_vm._s(item.name))]),(item.childList)?_c('i',{staticClass:"el-icon-arrow-down",style:({
                  color:
                    _vm.activeIndex == index
                      ? '#FC822C'
                      : _vm.scrollTop === 0
                      ? '#FFF'
                      : '#333',
                })}):_vm._e(),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[(item.childList)?_c('ul',{ref:"systemItem",refInFor:true,class:[
                    'system-item',
                    item.isMouseEnter
                      ? 'system-item-enter'
                      : 'system-item-not-enter',
                  ],on:{"mouseenter":function($event){return _vm.handleMouseEnter(item)}}},[_c('li',{staticClass:"orange-block"}),_vm._l((item.childList),function(it,index2){return _c('li',{key:index2,staticClass:"system-item-obj",on:{"click":function($event){return _vm.onClickToNextPage(it, index)}}},[_vm._v(" "+_vm._s(it.name)+" ")])})],2):_vm._e()])],1)}),0)])]),_c('el-col',{staticClass:"hidden-sm-and-down",attrs:{"xs":0,"sm":6,"md":7,"lg":7,"xl":7}},[_c('div',{staticClass:"head-phone",style:({ color: _vm.scrollTop === 0 ? '#FFF' : '#333' })},[_vm._v(" 热线电话: "),(_vm.scrollTop >= 64)?_c('img',{attrs:{"src":require("../images/smart-elderly/phone.png"),"alt":""}}):_c('img',{attrs:{"src":require("../images/smart-elderly/phone-white.png"),"alt":""}}),_c('span',{style:({ color: _vm.scrollTop === 0 ? '#FFF' : '#333' })},[_vm._v("400-763-5656")])])]),_c('el-col',{staticClass:"dropdown-box hidden-md-and-up",attrs:{"xs":12,"sm":12,"md":0,"lg":0,"xl":0}},[_c('el-dropdown',{attrs:{"hide-on-click":true,"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('el-button',{staticClass:"menu-btn",attrs:{"size":"small"}},[_c('i',{staticClass:"el-icon-s-operation"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":""}},[_vm._v("首页")]),_c('el-dropdown-item',{attrs:{"command":"system/home"}},[_vm._v("养老系统")]),_c('el-dropdown-item',{attrs:{"command":"Device"}},[_vm._v("养老设备")]),_c('el-dropdown-item',{attrs:{"command":"office"}},[_vm._v("掌上办公")]),_c('el-dropdown-item',{attrs:{"command":"about"}},[_vm._v("关于我们")]),_c('el-dropdown-item',{attrs:{"command":"apply"}},[_vm._v("申请试用")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }