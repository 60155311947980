<template>
  <div class="device">
    <!-- 硬件设备养老家居一览图 -->
    <div class="col-center home-device">
      <div class="modules-title">硬件设备养老家居一览图</div>
      <div class="modules-desc">
        老人生活区域，全方位智能硬件设备部署，实时监测
      </div>
      <el-row>
        <el-col :xs="24" :md="24" :lg="24" class="home-img">
          <el-image
            class="home-img-img"
            :src="require('../images/smart-elderly/home-system.png')"
            alt=""
            fit="cover"
          />
        </el-col>
      </el-row>
    </div>
    <!-- 智慧养老离不开硬件支持 -->
    <div class="col-center smart-device">
      <div class="modules-title smart-device-title">智慧养老离不开硬件支持</div>
      <div class="modules-desc smart-device-desc">智慧养老离不开硬件支持</div>
      <smartDevice class="device-matter" :productList = 'productList'></smartDevice>
    </div>
    <!-- 适老化产品，让老人生活更安心舒心 -->
    <div class="col-center eldly-device">
      <img
        class="ball ball-1"
        src="../images/smart-elderly/ball-1.png"
        alt=""
      />
      <img
        class="ball ball-2"
        src="../images/smart-elderly/ball-2.png"
        alt=""
      />
      <div class="ball ball-3"></div>
      <img
        class="ball ball-4"
        src="../images/smart-elderly/ball-4.png"
        alt=""
      />
      <div class="modules-title">适老化产品，让老人生活更安心舒心</div>
      <div class="modules-desc">给老人营造舒适的生活圈，让家人更放心</div>
       <smartDevice class="device-matter" :productList = 'productList'></smartDevice>
    </div>
  </div>
</template>
<script>
import smartDevice from "@/components/device/SmartDevice.vue";

export default {
  components: {
    smartDevice,
  },
  data() {
    return {
      productList: [
        {
          name: "老人居家跌倒看护服务套装",
          imgUrl:
            "https://mfs.ys7.com/mall/5c7eb987e8e4f99f28562616d75d8501_middle.jpg?6075572652",
          desc: "①包含2年自主居家跌倒看护服务。②7X24小时多种类异常监测。",
        },
        {
          name: "跌倒检测雷达",
          imgUrl:
            "https://mfs.ys7.com/mall/a22b3e839377912b5457e70d22900937_middle.png?6075572652",
          desc: "①全面的睡眠监测。②异常心率跟踪。③睡眠呼吸暂停风险评估。④无佩戴约束。",
        },
        {
          name: "无感睡眠伴侣",
          imgUrl:
            "https://mfs.ys7.com/mall/0db9b9413f6e77919f6acbfa93759646_middle.png?6075572652",
          desc: "①全面的睡眠监测。②异常心率跟踪.③睡眠呼吸暂停风险评估。④无佩戴约束。",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}

.device {
  width: 100%;
  background: #fff;
  //标题
  .modules-title {
    font-size: 46px;
    font-weight: 500;
    color: #000530;
    z-index: 1;
  }

  .modules-desc {
    font-size: 18px;
    font-weight: 500;
    color: #737C8B;
    margin-top: 16px;
    z-index: 1;
  }
  //养老设备一览图
  .home-device {
    margin-top: 64px;
    box-sizing: border-box;
    .home-img {
      display: block;
      width: 100%;
      background: #FFFFFF;
      margin: 0 auto;
      margin-top: 20px;
      .home-img-img {
        max-width: 1208px;
        height: auto;
      }
    }
  }

  //  智慧养老 硬件支持
  .smart-device {
    position: relative;
    margin-top: 60px;
    padding-top: 118px;
    // todo 修改路径 需要使用网络资源图片
    background-image: url('https://img.iotccb.com/fuyang/taihe/circle/169033312777699.png');
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 60px;
    background-size: 1440px 542px;
    box-sizing: border-box;
    .smart-device-title {
      color: #fff;
    }
    .smart-device-desc {
      color: #fff;
    }
  }

  // 适老化产品
  .eldly-device {
    padding: 150px 0 160px 0;
    position: relative;
    box-sizing: border-box;

    .ball {
      position: absolute;
    }

    .ball-1 {
      width: 65px;
      height: 65px;
      top: 70px;
      left: 10%;
    }

    .ball-2 {
      width: 48px;
      height: 48px;
      top: 250px;
      left: 30%;
    }

    .ball-3 {
      top: 50px;
      left: 60%;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background: linear-gradient(180deg, #8EFFFA 0%, rgba(120, 245, 239, 0) 100%);
    }

    .ball-4 {
      width: 48px;
      height: 48px;
      top: 300px;
      left: 80%;
    }
  }
  .device-matter {
    padding-top: 63px;
  }
}

@media screen and (max-width: 992px) {
}
</style>
