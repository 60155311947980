<template>
  <div class="about-us-contain">
    <el-image
      class="about-us-head-img"
      :src="require('../images/smart-elderly/aboutUs-banner.png')"
      alt=""
      fit="cover"
    />
    <!-- bannner 图上的文字 -->
    <div class="about-us-banner-txt-box hidden-md-and-down">
      <p class="about-us-banner-txt-title">致力于打造智慧养老品牌</p>
      <p class="about-us-banner-txt-desc">
        以质量求生存、以信誉求发展、以服务得结果
      </p>
    </div>
    <!-- 关于我们 -->
    <div class="col-center about-us">
      <div class="about-module-title">关于我们</div>
      <div class="grey-line-long">
        <div class="grey-line-short" />
      </div>
      <about></about>
    </div>
    <!-- 企业文化 -->
    <div class="col-center culture-matter">
      <div class="about-module-title">企业文化</div>
      <div class="grey-line-long">
        <div class="grey-line-short" />
      </div>
      <culture class="culture-box"></culture>
    </div>
    <!-- 企业资质 -->
    <div class="col-center certification-matter">
      <div class="about-module-title">企业资质</div>
      <div class="grey-line-long">
        <div class="grey-line-short" />
      </div>
      <certification></certification>
    </div>
    <!-- 联系我们 -->
    <div class="col-center connect-matter">
      <div class="about-module-title">联系我们</div>
      <div class="grey-line-long">
        <div class="grey-line-short" />
      </div>
      <baidu-map
        class="ditu"
        :scroll-wheel-zoom="true"
        :center="location"
        :zoom="zoom"
        :markers="markers"
        ak="sOwhibZUh1YpYUfDiA8cM27isU6b7Yhw"
      >
        <bm-view style="width: 100%; height: 500px; flex: 1"></bm-view>
        <bm-marker
          :position="{ lng: locationData.lng, lat: locationData.lat }"
          :dragging="false"
          content="22222222222222222"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-label
            content="地址: 合肥市包河区万达广场7号楼2602"
            :labelStyle="{
              color: '#333',
              border: 'none',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px ',
            }"
            :offset="{ width: '0', height: 30 }"
          />
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>
<script>
import about from "@/components/about/AboutUs.vue";
import certification from "@/components/about/certification.vue";
import contact from "@/components/about/contact.vue";
import culture from "@/components/about/culture.vue";
import BmView from "vue-baidu-map/components/map/MapView.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmLabel from "vue-baidu-map/components/overlays/Label.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  components: {
    about,
    certification,
    contact,
    culture,
    BaiduMap,
    BmView,
    BmMarker,
    BmLabel,
  },
  data() {
    return {
      locationData: {
        lng: 117.309213,
        lat: 31.863713,
      },
      location: {
        lng: 117.309213,
        lat: 31.863713,
      },
      zoom: 18,
      addressKeyword: "你我啊",
      markers: [
        {
          lng: 117.309213,
          lat: 31.863713,
          name: "合肥市包河区万达广场7号楼2602",
          showFlag: false,
          number: "1",
          buildName: "222222",
        },
      ],
    };
  },
  created() {},

  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.col-center {
  display: block;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
}
//  顶部导航栏颜色

.about-us-contain {
  background: #fff;
  width: 100%;
  position: relative;
  .about-module-title{
    font-size: 35px;
    font-weight: 500;
    color: #000530;
  }
  .about-us-banner-txt-box{
    //opacity: 0; /** 初始状态为不可见 */
    animation: 2s fade-in ease-out; /** 动画属性 */
  }
  @keyframes fade-in{
    0% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .about-us-banner-txt-box {
    max-width: 1440px;
    height: 531px;
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translate(-50%, 0%);
    //padding-left: 157px;
    box-sizing: border-box;
    text-align: center;

    .about-us-banner-txt-title {
      font-size: 50px;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 139px;
    }

    .about-us-banner-txt-desc {
      padding: 0;
      margin: 0;
      font-size: 22px;
      color: #FFFFFF;
      line-height: 25px;
    }
  }

  .about-us-head-img {
    width: 100%;
    height: 563px;
    background: #D8D8D8;
  }

  .about-us {
    max-width: 1440px;
    margin: 0 auto;
    font-size: 35px;
    font-weight: 500;
    color: #000530;
    padding: 60px 0px;
  }

  .culture-matter {
    max-width: 1440px;
    // height: 890px;
    box-sizing: border-box;
    background: #f8f8f8;
    padding: 38px 0;
    overflow: auto;

    .culture-box {
      margin-top: 98px;
    }
  }

  .certification-matter {
    padding-top: 60px;
    box-sizing: border-box;
    background: #fff;
  }

  .connect-matter {
    background: #f8f8f8;
    max-width: 1440px;
    padding: 60px 0 150px 0;
    margin-top 150px;
    .ditu {
      max-width  1240px !important;
      margin 0 auto;
      margin-top: 72px;
    }
  }

  .grey-line-long {
    // width: 1240px;
    max-width: 1240px;
    margin: 0 auto;
    border-bottom: 2px solid #BEC5D1;
    margin-top: 40px;
    position: relative;

    .grey-line-short {
      width: 256px;
      height: 14px;
      background: #BEC5D1;
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}
@media screen and (max-width: 992px) {
  .about-us-banner-txt-box{
    display none;
  }
  .about-us-banner-txt-box{
    display none !important;
  }
  .about-us-contain{
    .about-us-head-img{
      height 300px;
    }
  }
}
</style>
