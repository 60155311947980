<template>
  <div class="monitor">
    <el-row class="monitor-row">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="monitor-item">
          <img
            class="monitor-item-img"
            src="../../images/smart-elderly/old-man-pager.png"
            alt=""
          />
          <div class="monitor-item-content">
            老人在家突发情况按下急事紧急呼叫按钮，救护人员第一时间赶来
          </div>
          <div class="monitor-item-title">紧</div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="monitor-item">
          <img
            class="monitor-item-img"
            src="../../images/smart-elderly/location-alarm.png"
            alt=""
          />
          <div class="monitor-item-content">
            老人意外跌倒，会启动自动报警器自动定位，让子女在外也无需担心老人安全，放心安心
          </div>
          <div class="monitor-item-title">急</div>
        </div> </el-col
      ><el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="monitor-item">
          <img
            class="monitor-item-img"
            src="../../images/smart-elderly/smoke-alarm.png"
            alt=""
          />
          <div class="monitor-item-content">
            老人在家突发情况按下急事紧急呼叫按钮，救护人员第一时间赶来
          </div>
          <div class="monitor-item-title">报</div>
        </div> </el-col
      ><el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="monitor-item">
          <img
            class="monitor-item-img"
            src="../../images/smart-elderly/water-alarm.png"
            alt=""
          />
          <div class="monitor-item-content">
            水浸传感器报警，这里内容需要补充，这里内容需要补充，这里内容需要补充
          </div>
          <div class="monitor-item-title">警</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.monitor-row {
  //padding: 0 100px;
  .el-col{
    margin-top 20px
  }
}

.monitor-item {
  width: 287px;
  height: 444px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 0px #CCE1FE;
  position: relative;
  margin: 0 auto;
  box-size: border-box;
}

.monitor-item-img {
  width: 287px;
  height: 222px;
}

.monitor-item-content {
  width: 287px;
  height: 222px;
  text-align: left;
  box-sizing: border-box;
  padding: 40px 20px 0 20px;
  font-size: 16px;
  color: #999999;
  line-height: 22px;
}

.monitor-item-title {
  width: 78px;
  height: 78px;
  background: #417DFF;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 78px;
  position: absolute;
  top 50%
  left 50%
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid #fff;
}
@media screen and (max-width: 992px) {

}
</style>
